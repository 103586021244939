import React from "react";
import ArrowLeft from "./arrow-left";
import ArrowRight from "./arrow-right";

const PrevArrow = ({
  slideCount,
  currentSlide,
  handleClick,
  onClick,
  showPrevArrow = true,
  ...props
}) => (
  <>
    {(showPrevArrow || currentSlide !== 0) && (
      <button
        {...props}
        aria-label="precedente"
        onClick={(e) => {
          onClick && onClick(e);
          handleClick && handleClick(e);
        }}
      >
        <ArrowLeft />
      </button>
    )}
  </>
);

const NextArrow = ({
  slideCount,
  currentSlide,
  handleClick,
  onClick,
  showNextArrow = true,
  slidesToShow,
  ...props
}) => (
  <>
    {(showNextArrow || currentSlide !== slideCount - slidesToShow) && (
      <button
        {...props}
        aria-label="successivo"
        onClick={(e) => {
          onClick && onClick(e);
          handleClick && handleClick(e);
        }}
      >
        <ArrowRight />
      </button>
    )}
  </>
);

export { PrevArrow, NextArrow };
