import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import Cta from "../elements/cta";

const CardLayout = ({ data, isDetail = false }) => {
  const { moduleTitle, moduleBgColor, card } = data;

  return (
    <section className="card-layout" style={{ backgroundColor: moduleBgColor?.value }}>
      <div className="container">
        {moduleTitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="card-layout__title">{moduleTitle.value}</h2>
            </div>
          </div>
        )}
        {card && (
          <div className="card-layout__cards">
            {(isDetail === true
              ? card
              : card?.filter((item) => item?.content?.hideCard?.value === false)
            ).map((item, key) => (
              <div className="card-layout__card" key={key}>
                {item?.content?.cardImage && (
                  <div className="card-layout__image">
                    <GatsbyImage
                      image={getImage(item.content.cardImage?.node)}
                      alt={item.content.cardImage?.value?.description || ""}
                    />
                  </div>
                )}
                {(item?.content?.cardTitle?.value || item?.content?.cardDescription?.value) && (
                  <div className="card-layout__text">
                    {item?.content?.cardTitle?.value && (
                      <h3 className="card-layout__card-title">{item.content.cardTitle.value}</h3>
                    )}
                    {item?.content?.cardDescription?.value && (
                      <div
                        className="card-layout__card-description"
                        dangerouslySetInnerHTML={{ __html: item.content.cardDescription.value }}
                      ></div>
                    )}
                  </div>
                )}
                {item?.content?.cta && <Cta cta={item.content.cta} />}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(CardLayout);
export const fragment = graphql`
  fragment JskCardLayoutFragment on LiferayJskCardLayout {
    liferayFields {
      siteId
      articleId
    }
    moduleTitle {
      value
    }
    moduleBgColor {
      value
    }
    card {
      content {
        cardKey {
          value
        }
        hideCard {
          value
        }
        cardImage {
          value {
            description
          }
          node {
            gatsbyImageData(width: 345)
          }
        }
        cardTitle {
          value
        }
        cardDescription {
          value
        }
        cta {
          value
          content {
            ctaLink {
              value
            }
          }
        }
      }
    }
  }
`;
