import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";

const FasciaIconeTesto = ({ data }) => {
  const { moduleTitle, element } = data;

  const getSlideIcon = (item) => {
    return withArtDirection(getImage(item.content.image.node), [
      {
        media: "(max-width: 768px)",
        image: getImage(item.content.image.nodeMobile),
      },
    ]);
  };

  return (
    <section className="section">
      <div className="container">
        {moduleTitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="fascia-icone-testo__module-title">{moduleTitle.value}</h2>
            </div>
          </div>
        )}
        {element && (
          <div className="row px-md-3">
            {element.map((item, key) => (
              <div className="col-12 p-md-0 p-auto col-md-6" key={key}>
                <div className="fascia-icone-testo d-flex align-items-center">
                  {item.content?.image?.node && (
                    <GatsbyImage
                      image={getSlideIcon(item)}
                      className="fascia-icone-testo__img flex-shrink-0"
                      alt={""}
                    />
                  )}
                  <div className="fascia-icone-testo__text flex-grow-1 text-left">
                    {item?.content?.title?.value && (
                      <div className="fascia-icone-testo__title">{item.content.title.value}</div>
                    )}
                    {item.content?.description?.value && (
                      <div
                        className="fascia-icone-testo__desc text-left"
                        dangerouslySetInnerHTML={{ __html: item.content.description.value }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(FasciaIconeTesto);
export const fragment = graphql`
  fragment JskFasciaIconeTestoFragment on LiferayJskFasciaIconeTesto {
    liferayFields {
      siteId
      articleId
    }
    moduleTitle {
      value
    }
    element {
      content {
        key {
          value
        }
        image {
          value {
            description
          }
          node {
            gatsbyImageData(formats: PNG, width: 100, layout: FIXED)
          }
          nodeMobile: node {
            gatsbyImageData(width: 50, layout: FIXED)
          }
        }
        title {
          value
        }
        description {
          value
        }
      }
    }
  }
`;
